import ParticleBackground from '../components/ParticleBackground';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const Emphasis = styled(Box)(({theme}) => ({
  color: "#F572F3",
  display: 'inline',
  fontSize: '1em'
}))

const Home = () => {
  return (
    <div style={{ height: '100%', alignItems: 'center'}}>
      <Box sx={{ position: 'fixed', zIndex: '-1' }}>
        <ParticleBackground />
      </Box>
      <Box sx={{ marginTop: '30px', textAlign: 'center' }} >
        <Box>
          <Typography sx={{ fontSize: '3em' }}>
            Dear <Emphasis component="span">Grace</Emphasis><br/>
          </Typography>
          <Typography component="p" sx={{ fontSize: '1em', padding: '0 0em', whiteSpace: 'pre-line' }}>
Happy birthday for the big two-nine!<br/>
I hope your birthday is mighty fine<br/>
Full of some good pinot noir wine<br/>
And juicy gossip heard down the grape vine<br/>
I am so glad to call you mine<br/><br/>

You told me this card had to be long<br/>
And that it would be terribly wrong<br/>
To recycle even a single word<br/>
With this, I concurred<br/>
So now I have gone to great lengths<br/>
Using my technical strengths<br/>
To deliver this epic rhyme<br/>
So that unoriginality is not my crime<br/><br/>

It truly has been big year<br/>
From our trip to Thailand to regretfully buying hiking gear<br/>
Who would have thunk it would be me<br/>
Bailing on our walk partly due to a sore knee<br/>
Now instead of doing our 20 flights<br/>
Trying to emulate the dizzying heights<br/>
We can relax with a glass of port<br/>
In an 18th Century Portuguese fort<br/>
Eating chicken and chips<br/>
Enjoying the sun and taking dips<br/>
Hunting down the best custard tarts<br/>
And walking it off at a museum of modern arts<br/><br/>

Thank you for always being by my side<br/>
Even before becoming my bride<br/>
You made sacrifices when I went back to uni<br/>
Whilst everyone told me I was looney<br/>
You accommodate my quirks and OCD<br/>
Even when I go on a lotto spree<br/>
You handle all the issues with strata<br/>
As though TAFE were already your alma mater<br/>
And in winter you made us use the lecky B<br/>
With which I am now inclined to agree<br/><br/>

We have also had our fare share of faux pas<br/>
And funny moments looking like a right arse<br/>
From telling someone their ex is horrible<br/>
Whilst their expression remained incorrigible<br/>
To boasting about your butter feet<br/>
Loud enough for all to hear in our street<br/>
So many moments from which we are scarred<br/>
But, hey, at least we got to go to Charred<br/><br/>

I love you more than anything<br/>
Even if diamonds are your chosen bling<br/>
I also love the family we have become<br/>
The whole is truly greater than the sum<br/>
And we have so much more to look forward to<br/>
So with that I will bid this poem adieu<br/><br/>

Lots of love,<br/>
<Emphasis component="span">Adam</Emphasis> 🩷🩷🩷<br/><br/>
          </Typography>
        </Box>
    </Box>
    </div>
  )
}

export default Home;